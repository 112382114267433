import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Order, PaymentMethod } from '@app/shared';
import {LanguageService} from "@app/core/services/language.service";

@Component({
  selector: 'app-payment',
  templateUrl: 'payment.component.html',
  styleUrls: ['../review-modal.component.scss'],
})
export class PaymentComponent implements OnInit {
  @Input() order: Order;
  @Input() enableStripe: boolean;
  @Input() enableRoomCharge: boolean;
  @Input() chargeToMyRoom: boolean;
  @Output() mainReview = new EventEmitter();
  @Output() savePaymentUpdates = new EventEmitter();
  localPaymentInfo: PaymentMethod;
  constructor(public languageService: LanguageService,) {
  }

  ngOnInit() {
    this.localPaymentInfo = { ...this.order.payment };
    if(this.chargeToMyRoom !== undefined) {
        this.localPaymentInfo.chargeToMyRoom = this.chargeToMyRoom;
        this.savePaymentUpdates.emit(this.localPaymentInfo);
    }
  }

  paymentMethod(chargeToMyRoom: boolean) {
    this.localPaymentInfo.chargeToMyRoom = chargeToMyRoom;
    // if (!chargeToMyRoom) {
    //   this.savePaymentUpdates.emit(this.localPaymentInfo);
    // }
  }
}
