import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Restaurant, Order } from '../../models';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import {LanguageService} from "@app/core/services/language.service";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: 'confirmation-modal.component.html',
  styleUrls: ['confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() order: Order;
  @Input() restaurant: Restaurant;
  @ViewChild('ionContentOrder', { read: ElementRef }) private ionContentOrder: ElementRef;
  confirmedTime: string;
  working = true;

  constructor(
    private modalController: ModalController,
    private element: ElementRef,
    public languageService: LanguageService,
  ) {}

  ngOnInit() {
    if(this.restaurant.deliveryWaitTimeInMinutes > 0) {
      if(this.order.deliveryTime.asap) {
        this.confirmedTime = 'By ' + this.order.deliveryTime.confirmedDeliveryTime;
      } else {
        this.confirmedTime = this.order.deliveryTime.requestedTime;
        if(this.order.deliveryTime.deliverNextDay) {
          this.confirmedTime += ' (Tomorrow)';
        }
        this.confirmedTime = 'At ' + this.confirmedTime;
      }
    } else {
      this.confirmedTime = '';
    }
    // this.confirmedTime = this.order.deliveryTime.confirmedDeliveryTime.toLocaleString(DateTime.TIME_SIMPLE);
    // this.confirmedDeliveryTime = this.order.deliveryTime.confirmedDeliveryTime;
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async closeModalWithId() {
    const confirmModalId: string = this.element.nativeElement.parentElement.parentElement.id;
    await this.modalController.dismiss({ closePrevModal: true }, null, confirmModalId);
  }

  async openHelpModal() {
    const helpModal = await this.modalController.create({
      component: HelpModalComponent,
      cssClass: ['auto-height', 'modal-backdrop-opacity'],
      componentProps: {
        order: { ...this.order },
        contactNumber: this.restaurant.contactNumber,
      },
    });
    helpModal.onWillDismiss().then((data) => {
      if (data.data?.closePrevModal) {
        this.closeModalWithId();
      }
    });
    return await helpModal.present();
  }
}
