import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Category } from '@app/shared';

@Component({
  selector: 'app-unlimited-modal',
  templateUrl: 'unlimited-modal.component.html',
  styleUrls: ['unlimited-modal.component.scss'],
})
export class UnlimitedModalComponent {
  @Input() data: Category[];

  constructor(
    private modalController: ModalController,
  ) {}

  async goToUnlimited() {
    await this.modalController.dismiss();
    // TODO open unlimited full page modal
  }

  async cancel() {
    await this.modalController.dismiss();
  }
}
