import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MenuItemModalComponent } from './services';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [MenuItemModalComponent],
    imports: [CommonModule, IonicModule, FormsModule],
  exports: [IonicModule, MenuItemModalComponent],
  providers: [],
})
export class CoreModule {}
