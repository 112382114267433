import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  NgZone,
  QueryList,
  ViewChildren,
  OnDestroy
} from '@angular/core';
import {
  trigger,
  style,
  transition,
  state,
  animate,
} from '@angular/animations';
import {ModalController, IonItem, GestureController, Gesture, AlertController} from '@ionic/angular';
import {
  MENUITEMMODALINITIALVH,
  ItemOrdered,
  COLLAPSEDMENUITEMHEADERHEIGHT,
  MenuItemStyleUpdates,
  ItemOrderedOption,
} from '../../../shared/models';
import { ScrollingContentService } from '../scrolling-content.service';
import { OptionsModalComponent } from '../../../shared/components/options-modal/options-modal.component';
import {RestaurantService} from '@app/core/services/restaurant.service';
import {DiscountService} from "@app/core/services/discount.service";
import {convertTime12to24} from "@app/utils/common-utils";

@Component({
  selector: 'app-menu-item-modal',
  templateUrl: 'menu-item-modal.component.html',
  styleUrls: ['menu-item-modal.component.scss'],
  animations: [
    trigger('headerNameAnimation', [
      state('show', style({ transform: 'translateY(0)', opacity: 1, height: '*' })),
      state('hide', style({ transform: 'translateY(100%)', opacity: 0, height: '0' })),
      transition('hide <=> show', [animate('250ms ease-in-out')]),
    ]),
    trigger('contentNameAnimation', [
      state('show', style({ transform: 'translateY(0)' })),
      state('hide', style({ transform: 'translateY(-100%)' })),
      transition('hide <=> show', [animate('250ms ease-in-out')]),
    ]),
  ],
})
export class MenuItemModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() itemToOrder: ItemOrdered;
  @Input() hidePricing: string;
  @ViewChild('ionToolbar', { read: ElementRef }) private ionToolbar: ElementRef;
  @ViewChild('ionContentElement', { read: ElementRef }) private ionContentElement: ElementRef;
  @ViewChildren(IonItem, { read: ElementRef }) private ionItems: QueryList<ElementRef>;
  @ViewChild('contentTopGrid', { read: ElementRef }) private contentTopGrid: ElementRef;
  itemImageUrl: string;
  initialHeaderHeight: number;
  itemNameInHeader = false;
  // orderCount = 1;
  maxContentScrollUp: number;  // Vertical scroll amount so bottom of ion-content is visible
  gesture: Gesture;
  currentGestureScrollY = 0;
  combinedPrevGesturesTotalScrollY = 0;
  prevGestureDeltaY = 0;
  working = false;
  addZero = false;
  subOptionsText: string[] = [];
  discountApplicable = false;

  constructor(
    private modalController: ModalController,
    private ngZone: NgZone,
    private gestureController: GestureController,
    private element: ElementRef,
    private scrollingContentService: ScrollingContentService,
    public restaurantService: RestaurantService,
    private alertController: AlertController,
    public discountService: DiscountService,
  ) {}

  ngOnInit() {
    if (this.itemToOrder.photoUrl) {
      this.itemImageUrl = `url(${this.itemToOrder.photoUrl})`;
    }
    this.initialHeaderHeight = Math.round(window.innerHeight * MENUITEMMODALINITIALVH);
    if (!this.itemToOrder.options) {
      this.addZero = false;
    } else {
      this.addZero = this.itemToOrder.options.filter((option) => option.required).length > 0;
    }
    this.discountApplicable = this.discountService.isApplicable();
  }

  ngAfterViewInit() {
    if (this.itemToOrder.photoUrl) {
      // only dynamically modify header css if this itemToOrder has a photoUrl
      // this.initializeElements();
      let limitScroll: boolean;
      this.gesture = this.gestureController.create({
        gestureName: 'moveYDirection',
        el: this.element.nativeElement,
        threshold: 5,
        direction: 'y',
        passive: true,
        onMove: (detail) => {
          if (detail.deltaY) {
            if (detail.deltaY < this.currentGestureScrollY) {
              // scrolling up, so limit currentGestureScrollY updates when scrolling above maxContentScrollUp
              limitScroll = -1 * this.maxContentScrollUp > this.currentGestureScrollY + this.combinedPrevGesturesTotalScrollY;
            } else {
              // scrolling down, so limit currentGestureScrollY updates when at bottom of scrolling
              limitScroll = 0 < this.currentGestureScrollY + this.combinedPrevGesturesTotalScrollY;
            }
            if (!limitScroll) {
              this.currentGestureScrollY = detail.deltaY;
              const headerUpdates: MenuItemStyleUpdates = this.scrollingContentService.updateMenuItemStyles(
              this.currentGestureScrollY + this.combinedPrevGesturesTotalScrollY,
              COLLAPSEDMENUITEMHEADERHEIGHT,
              this.initialHeaderHeight,
              this.maxContentScrollUp,
              this.currentGestureScrollY - this.prevGestureDeltaY ? 'up' : 'down',
              );
              // console.log('headerUpdates = ', headerUpdates);
              this.ionToolbar.nativeElement.style.height = headerUpdates.headerHeight;
              this.ionToolbar.nativeElement.style.setProperty('backdrop-filter', headerUpdates.headerBackdropFilter);
              if (headerUpdates.topGridMarginTop) {
                this.contentTopGrid.nativeElement.style.setProperty('margin-top', headerUpdates.topGridMarginTop);
              }
              this.ngZone.run(() => {
                this.itemNameInHeader = headerUpdates.nameInHeader;
              });
              this.prevGestureDeltaY = this.currentGestureScrollY;
            }
          }
        },
        onEnd: (detail) => {
          this.combinedPrevGesturesTotalScrollY = this.combinedPrevGesturesTotalScrollY + this.currentGestureScrollY;
        }
      });
      // this.gesture.enable();
    }
  }

  initializeElements() {
    this.ionToolbar.nativeElement.style.height = `${this.initialHeaderHeight}px`;
    this.ionContentElement.nativeElement.style.setProperty('--overflow', 'hidden');
    setTimeout(() => {
      const itemCount = this.ionItems.toArray().length;
      const lastIonItem = this.ionItems.toArray()[itemCount - 1];
      this.maxContentScrollUp = 20 + this.contentTopGrid.nativeElement.offsetHeight
        + itemCount * (lastIonItem?.nativeElement.offsetHeight) - this.ionContentElement.nativeElement.clientHeight;
    }, 500);
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async openOptionsModal(option: ItemOrderedOption) {
    const modal = await this.modalController.create({
      component: OptionsModalComponent,
      cssClass: ['auto-height', 'modal-backdrop-opacity'],
      componentProps: {
        option,
      },
    });
    modal.onWillDismiss().then((data) => {
      if (data) {
        if (data.data) {
          this.updateItemPrice();
          // this.initializeElements();
        }
      }
    });
    return await modal.present();
  }

  updateItemPrice() {
    // Update priceAdder based on options selected
    this.itemToOrder.totalPriceAdder = 0;
    this.addZero = false;
    this.subOptionsText = [];
    this.itemToOrder.options.forEach((option) => {
      let subOptionText = '';
      let optionPriceAdder = 0;
      option.subOptions.forEach((subOption) => {
        if (subOption.selected) {
          optionPriceAdder += subOption.price;
          subOptionText = subOptionText === ''
            ? subOptionText = (subOption.translatedName || subOption.name)
            : subOptionText = `${subOptionText}, ${(subOption.translatedName || subOption.name)}`;
        }
      });
      this.itemToOrder.totalPriceAdder += optionPriceAdder;
      this.subOptionsText.push(subOptionText);
      if (option.required && !option.selectionCriteriaMet) {
        this.addZero = true;
      }
    });
  }

  async itemAdded(itemToAdd: ItemOrdered) {
    let hhmm = null;
    if(this.restaurantService.preOrder?.preOrderSelected) {
      let hhmm24 = convertTime12to24(this.restaurantService.preOrder?.confirmedDeliveryTime);
      hhmm = `${hhmm24}:00`;
    }
    //check if current time allows adding item for the restaurant
    const allowedHHMM = this.restaurantService.canOrderAtTime(this.restaurantService.selectedPeriodIndex, hhmm);
    if(allowedHHMM != null && allowedHHMM.length === 0) {
      this.working = true;
      await this.modalController.dismiss({ itemToAdd: { ...itemToAdd, orderCount: this.itemToOrder.count } });
      this.working = false;
      // // 750ms delay to emulate network/server response
      // setTimeout(() => {
      //   this.modalController.dismiss({ itemToAdd: { ...itemToAdd, orderCount: this.itemToOrder.count } });
      //   this.working = false;
      // }, 750);
    } else {
      let message;
      if(allowedHHMM == null) {
        message = 'No Orders accepted at this time';
      } else {
        message = '<b>Available Timings<br>' + allowedHHMM.join('<br>') + '</b>';
      }
      const alert = await this.alertController.create({
        // header: 'Error adding item to cart',
        subHeader: 'Error adding item to cart',
        message,
        buttons: ['OK']
      });
      await alert.present();
    }
  }

  minusOne() {
    if (!this.addZero) {
      this.itemToOrder.count = Math.max(this.itemToOrder.count - 1, 1);
    }
  }

  plusOne() {
    if (!this.addZero) {
      this.itemToOrder.count = Math.max(this.itemToOrder.count + 1, 1);
    }
  }

  ngOnDestroy() {
    this.gesture?.destroy();
  }

  shouldShowPricing() {
    return (this.hidePricing !== 'true' || ((+this.itemToOrder.price) > 0));
  }
}
