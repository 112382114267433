import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StripeService} from '@app/core/services/stripe.service';
import {ItemOrdered, Order} from '@app/shared';

@Component({
  selector: 'app-stripe-card-payment',
  templateUrl: 'stripe-card-payment.component.html',
  styleUrls: ['../review-modal.component.scss'],
})
export class StripeCardPaymentComponent implements OnInit, OnDestroy {
  amount = 0;
  constructor(private stripeService: StripeService) {}
  @Input() order: Order;
  ngOnInit() {
    this.stripeService.mount();
    // console.log(this.order);
    this.amount = this.order.chargesSummary.total;
    // if(this.order.deliveryRequest?.location === 'carry-out') {
    //   this.amount = this.order.chargesSummary.total - this.order.chargesSummary.deliveryFee;
    // }
  }
  ngOnDestroy() {
    // this.stripeService.unmount();
  }
}
