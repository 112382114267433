import {Injectable} from '@angular/core';
import {RestaurantService} from '@app/core/services/restaurant.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private strings: string[] = [];
    private spanishStrings: string[] = [];
    constructor(public restaurantService: RestaurantService,) {
        this.strings['attention'] = 'ATTENTION!';
        this.spanishStrings['attention'] = '¡ATENCIÓN!';

        this.strings['notes'] = 'Special Requests?';
        this.spanishStrings['notes'] = '¿Solicitudes especiales?';

        this.strings['placeholder_notes'] = 'Leave any requests, extras, or allergen info here.';
        this.spanishStrings['placeholder_notes'] = 'Deje aquí cualquier solicitud, extra o información sobre alérgenos.';

        this.strings['confirm'] = 'Please confirm:';
        this.spanishStrings['confirm'] = 'Por favor, confirma:';

        this.strings['confirm1'] = '1) Is this the correct room number for delivery?';
        this.spanishStrings['confirm1'] = '1) ¿Es correcto el número de habitación para la entrega?';

        this.strings['confirm2'] = '2) Is this the name on the reservation for this room?';
        this.spanishStrings['confirm2'] = '2) ¿Es este el nombre de la reserva para esta habitación?';

        this.strings['confirm_room_number'] = 'Room Number';
        this.spanishStrings['confirm_room_number'] = 'Número de Habitación';

        this.strings['confirm_name'] = 'Full Name Listed on Room Reservation';
        this.spanishStrings['confirm_name'] = 'Nombre Completo Registrado en La Reserva';

        this.strings['confirm_button'] = 'Confirm';
        this.spanishStrings['confirm_button'] = 'Confirmar';

        this.strings['edit_button'] = 'Edit';
        this.spanishStrings['edit_button'] = 'Editar';

        this.strings['place_order_button'] = 'PLACE ORDER';
        this.spanishStrings['place_order_button'] = 'REALIZAR PEDIDO';

        this.strings['review_order'] = 'Review Order';
        this.spanishStrings['review_order'] = 'Revisar Pedido';

        this.strings['guest_details'] = 'Guest Details';
        this.spanishStrings['guest_details'] = 'Detalles del Huésped:';

        this.strings['room_number'] = 'Room Number';
        this.spanishStrings['room_number'] = 'Número de Habitación';

        this.strings['placeholder_room_number'] = 'For Delivery';
        this.spanishStrings['placeholder_room_number'] = 'Para Entrega';

        this.strings['full_name'] = 'Full Name';
        this.spanishStrings['full_name'] = 'Nombre Completo';

        this.strings['full_name_room_reservation'] = 'Full Name on Room Reservation';
        this.spanishStrings['full_name_room_reservation'] = 'Nombre Completo en La Reserva';

        this.strings['placeholder_full_name'] = 'First & Last';
        this.spanishStrings['placeholder_full_name'] = 'Nombre y Apellido';

        this.strings['number_dining'] = 'Total Number of People';
        this.spanishStrings['number_dining'] = 'Total de Personas';

        this.strings['placeholder_number_dining'] = 'Number Dining';
        this.spanishStrings['placeholder_number_dining'] = 'Cantidad de Personas Cenando';

        this.strings['order_confirmed'] = 'Your Order Is Confirmed';
        this.spanishStrings['order_confirmed'] = 'Tu Pedido ha Sido Confirmado';

        this.strings['name'] = 'Name';
        this.spanishStrings['name'] = 'Nombre';

        this.strings['deliver_to_room'] = 'Deliver To Your Room #';
        this.spanishStrings['deliver_to_room'] = 'Entregar a La Habitación:';

        this.strings['your_order'] = 'Your Order';
        this.spanishStrings['your_order'] = 'Su Pedido';

        this.strings['view_menu'] = 'View Menu';
        this.spanishStrings['view_menu'] = 'Ver Menú';

        this.strings['hotel_room_delivery'] = 'Hotel Room Delivery';
        this.spanishStrings['hotel_room_delivery'] = 'Entrega en la Habitación';

        this.strings['pickup'] = 'Pickup';
        this.spanishStrings['pickup'] = 'Recoger';

        this.strings['help'] = 'HELP';
        this.spanishStrings['help'] = 'AYUDA';

        this.strings['help_queries'] = 'In case of any queries, call us at below number.';
        this.spanishStrings['help_queries'] = 'En caso de cualquier consulta, llámenos al siguiente número.';

        this.strings['placing_order'] = 'Placing your order';
        this.spanishStrings['placing_order'] = 'Realizando tu pedido';

        this.strings['dismiss'] = 'Dismiss';
        this.spanishStrings['dismiss'] = 'Cancelar';

        this.strings['delivery_time'] = 'Delivery Time';
        this.spanishStrings['delivery_time'] = 'Horario de Entrega';

        this.strings['pickup_time'] = 'Pickup Time';
        this.spanishStrings['pickup_time'] = 'Horario de Entrega';

        this.strings['deliver_at'] = 'Deliver at';
        this.spanishStrings['deliver_at'] = 'Entrega a las';

        this.strings['tomorrow'] = 'Tomorrow';
        this.spanishStrings['tomorrow'] = 'Mañana';

        this.strings['delivery_when_ready'] = 'Delivery When Ready';
        this.spanishStrings['delivery_when_ready'] = 'Entrega Cuando esté Terminado';

        this.strings['pickup_when_ready'] = 'Pickup When Ready';
        this.spanishStrings['pickup_when_ready'] = 'Entrega Cuando esté Terminado';

        this.strings['pickup_time'] = 'Pickup Time';
        this.spanishStrings['pickup_time'] = 'Solicitar Horario';

        this.strings['request_time'] = 'Request Time';
        this.spanishStrings['request_time'] = 'Solicitar Horario';

        this.strings['pickup_next_day'] = 'Pickup Next Day';
        this.spanishStrings['pickup_next_day'] = 'Entregar al Día Siguiente';

        this.strings['deliver_next_day'] = 'Deliver Next Day';
        this.spanishStrings['deliver_next_day'] = 'Entregar al Día Siguiente';

        this.strings['pickup_when_ready'] = 'Pickup when order is ready';
        this.spanishStrings['pickup_when_ready'] = 'Entrega cuando el pedido esté listo';

        this.strings['deliver_when_ready'] = 'Deliver when order is ready';
        this.spanishStrings['deliver_when_ready'] = 'Entrega cuando el pedido esté listo';

        this.strings['save'] = 'SAVE';
        this.spanishStrings['save'] = 'GUARDAR';

        this.strings['to_go_utensils'] = 'To Go Utensils';
        this.spanishStrings['to_go_utensils'] = 'Cubiertos para Llevar';

        this.strings['payment_method'] = 'Payment Method';
        this.spanishStrings['payment_method'] = 'Forma de Pago';

        this.strings['charge_to_room'] = 'Charge To My Room';
        this.spanishStrings['charge_to_room'] = 'Facturar a Mi Cuenta de Habitación';

        this.strings['charge_to_card'] = 'Charge to Credit/Debit Card';
        this.spanishStrings['charge_to_card'] = 'Pagar con Tarjeta de Crédito/Débito';

        this.strings['order_for_today'] = 'ORDER FOR TODAY';
        this.spanishStrings['order_for_today'] = 'PEDIDO PARA HOY';

        this.strings['pre_order_for_tomorrow'] = 'PRE-ORDER FOR TOMORROW';
        this.spanishStrings['pre_order_for_tomorrow'] = 'PEDIDO PARA MAÑANA';

        this.strings['delivery_time1'] = 'Delivery Time';
        this.spanishStrings['delivery_time1'] = 'El tiempo de entrega';

        this.strings['test'] = 'Test';
        this.spanishStrings['test'] = 'Prueba';

        this.strings['error_invalid_room_number'] = 'Please check room number. It does not appear to be valid.';
        this.spanishStrings['error_invalid_room_number'] = 'Por favor verifique el número de habitación. No parece ser válido.';
    }

    translate(propertyName: string) {
        if((this.restaurantService?.language === 'spanish'
                || this.restaurantService?.language === 'español')
            && this.spanishStrings[propertyName]) {
            return this.spanishStrings[propertyName];
        }
        return this.strings[propertyName];
    }
}
