import { Component, Input, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-language-modal',
  templateUrl: 'language-modal.component.html',
  styleUrls: ['language-modal.component.scss'],
})
export class LanguageModalComponent implements OnInit {
  @Input() languages: string;
  arrLanguages: string[];
  mapDisplayLanguage =  {
    'english': 'ENGLISH',
    'spanish': 'ESPAÑOL',
    'español': 'ESPAÑOL',
  };

  constructor(private modalController: ModalController,
  ) {}

  ngOnInit() {
    this.arrLanguages = this.languages.split(',');
    this.arrLanguages.unshift('english');
  }

  selectLanguage(language: string) {
    if(language.toLowerCase() === 'english') {
      this.modalController.dismiss('english');
    }
    this.modalController.dismiss(language?.toLowerCase());
  }
}
