import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Order, DeliveryTime } from '@app/shared';
import {DatePipe} from '@angular/common';
import {DateTime} from 'luxon';
import {LanguageService} from "@app/core/services/language.service";

@Component({
  selector: 'app-delivery-time',
  templateUrl: 'delivery-time.component.html',
  styleUrls: ['../review-modal.component.scss'],
})
export class DeliveryTimeComponent implements OnInit {
  @Input() order: Order;
  @Input() selectedDeliveryOption: string;
  @Output() mainReview = new EventEmitter();
  @Output() saveTimeUpdates = new EventEmitter();
  @ViewChild('deliveryTimeInput', { read: ElementRef }) private deliveryTimeInput: ElementRef;
  localDeliveryTime: DeliveryTime;
  requestedDeliveryTime: string;
  minuteValues = '0,5,10,15,20,25,30,35,40,45,50,55,';
  // defaultEarliestTime: string;
  // defaultLatestTime: string;

  constructor(
      private datePipe: DatePipe,
      public languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.localDeliveryTime = { ...this.order.deliveryTime };
    const remainder = 5 - (DateTime.now().minute % 5);
    this.requestedDeliveryTime = DateTime.now().plus({ minutes: (60+remainder) }).toUTC().toString();
    // this.defaultEarliestTime = this.localDeliveryTime.earliestDelivery.toLocaleString(DateTime.TIME_SIMPLE);
    // this.defaultLatestTime = this.localDeliveryTime.latestDelivery.toLocaleString(DateTime.TIME_SIMPLE);
  }

  deliveryTime(asap: boolean) {
    this.localDeliveryTime.asap = asap;
    if(asap) {
      // this.localDeliveryTime.confirmedDeliveryTime = this.requestedDeliveryTime;
      // dont set it here, instead set it at final confirmation dialog
    } else {
      this.deliveryTimeInput?.nativeElement?.open();
    }
  }

  updateRequestedDeliveryTime() {
    this.localDeliveryTime.requestedTime = this.datePipe.transform(this.requestedDeliveryTime, 'h:mm a');
  }
}
