import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Order } from '@app/shared';
import {LanguageService} from "@app/core/services/language.service";

@Component({
  selector: 'app-to-go-utensils',
  templateUrl: 'to-go-utensils.component.html',
  styleUrls: ['../review-modal.component.scss'],
})
export class ToGoUtensilsComponent implements OnInit {
  @Input() order: Order;
  @Output() mainReview = new EventEmitter();
  @Output() saveToGoUtensilsUpdates = new EventEmitter();
  toGoUtensils: boolean;

  constructor(
      public languageService: LanguageService,
  ) {}

  ngOnInit() {
    // console.log(this.order.extra?.toGoUtensils);
    this.toGoUtensils = !!this.order.extra?.toGoUtensils;
  }

  setToGoUtensils(toGoUtensils: boolean) {
    this.toGoUtensils = toGoUtensils;
  }

}
