import {Injectable} from '@angular/core';
import {loadStripe} from '@stripe/stripe-js';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    stripe: any;
    stripeElement: any;
    stripeCard: any;
    stripeStyle = {
        base: {
            color: '#32325d',
            // lineHeight: '24px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    };

    constructor() {
        // this.setupStripe();
    }

    async setupStripe() {
        this.stripe = await loadStripe(environment.stripeKey);
        this.stripeElement = this.stripe.elements();
    }

    // unmount() {
    //     this.stripeCard = null;
    // }

    async mount() {
        if(!this.stripeElement) {
            await this.setupStripe();
        }
        if(!this.stripeCard) {
            this.stripeCard = this.stripeElement.create('card', { style: this.stripeStyle });
            // console.log(this.stripeCard);
        }

        this.stripeCard.mount('#card-element');

        this.stripeCard.addEventListener('change', event => {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });
    }

    async getToken() {
        const result = await this.stripe.createToken(this.stripeCard);
        if (result.error) {
            const errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
        } else {
            console.log(result);
        }
        return result;
    }
}
