import { CategoriesModalComponent } from './categories-modal/categories-modal.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { OptionsModalComponent } from './options-modal/options-modal.component';
import { UnlimitedModalComponent } from './unlimited-modal/unlimited-modal.component';
import { OrderModalComponent } from './order-modal/order-modal.component';
import { ReviewModalComponent } from './review-modal/review-modal.component';
import { ReviewMainComponent } from './review-modal/review-main/review-main.component';
import { DeliveryOptionsComponent } from './review-modal/delivery-options/delivery-options.component';
import { DeliveryTimeComponent } from './review-modal/delivery-time/delivery-time.component';
import { PaymentComponent } from './review-modal/payment/payment.component';
import { CardEntryComponent } from './review-modal/cardEntry/cardEntry.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { ChargesInfoModalComponent } from './charges-info-modal/charges-info-modal.component';
import { LanguageModalComponent } from '@app/shared/components/language-modal/language-modal.component';
import { ToGoUtensilsComponent } from '@app/shared/components/review-modal/to-go-utensils/to-go-utensils.component';
import { PreOrderModalComponent } from '@app/shared/components/pre-order-modal/pre-order-modal.component';

export const sharedComponents = [
  CategoriesModalComponent,
  MenuItemComponent,
  OptionsModalComponent,
  UnlimitedModalComponent,
  OrderModalComponent,
  ReviewModalComponent,
  ReviewMainComponent,
  DeliveryOptionsComponent,
  DeliveryTimeComponent,
  PaymentComponent,
  CardEntryComponent,
  ConfirmationModalComponent,
  HelpModalComponent,
  ChargesInfoModalComponent,
  LanguageModalComponent,
  PreOrderModalComponent,
  ToGoUtensilsComponent
];

export * from './categories-modal/categories-modal.component';
export * from './menu-item/menu-item.component';
export * from './options-modal/options-modal.component';
export * from './unlimited-modal/unlimited-modal.component';
export * from './order-modal/order-modal.component';
export * from './review-modal/review-modal.component';
export * from './review-modal/review-main/review-main.component';
export * from './review-modal/delivery-options/delivery-options.component';
export * from './review-modal/delivery-time/delivery-time.component';
export * from './review-modal/payment/payment.component';
export * from './review-modal/cardEntry/cardEntry.component';
export * from './confirmation-modal/confirmation-modal.component';
export * from './help-modal/help-modal.component';
export * from './charges-info-modal/charges-info-modal.component';
export * from './pre-order-modal/pre-order-modal.component';
export * from './language-modal/language-modal.component';
