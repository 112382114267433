import { DateTime } from 'luxon';
import {Address, Restaurant, Category, MenuItem, ItemOption, ItemSubOption, Period} from './restaurant';
import { Order, Guest, DeliveryRequest, DeliveryTime, PaymentMethod, CreditCardInfo } from './order';

export const COLLAPSEDMENUITEMHEADERHEIGHT = 60;
export const MENUITEMMODALINITIALVH = 0.4;

export const STRIPE_PROCESSING_FEES_FLAT = 0.3;
export const STRIPE_PROCESSING_FEES_PERCENTAGE = 2.9;

export const ADDRESS: Address = {
  street: '246 Jonestown Rd',
  city: 'Ventura',
  state: 'CA',
  zip: '93456',
};

export const ITEMSUBOPTIONS1: ItemSubOption[] = [
  {
    name: 'Thick Crust',
    price: 2,
    id: 'dummy',
  },  {
    name: 'Regular Crust',
    price: 0,
    id: 'dummy',
  },
  {
    name: 'Thin Crust',
    price: 1.5,
    id: 'dummy',
  },
];

export const ITEMSUBOPTIONS2: ItemSubOption[] = [
  {
    name: 'Pepperoni',
    price: 1.75,
    id: 'dummy',
  },  {
    name: 'Veggies',
    price: 1,
    id: 'dummy',
  },
  {
    name: 'Pineapple',
    price: 1.5,
    id: 'dummy',
  },
];

export const ITEMOPTIONS1: ItemOption[] = [
  {
    name: 'Crust',
    required: true,
    description: '1 Required, 1 Max',
    subOptions: ITEMSUBOPTIONS1,
    minSelections: 1,
    maxSelections: 1,
    id: 'dummy',
  },
  {
    name: 'Toppings',
    required: false,
    description: 'Optional, Up to 3',
    subOptions: ITEMSUBOPTIONS2,
    minSelections: 0,
    maxSelections: 3,
    id: 'dummy',
  },
  {
    name: 'Salad Dressing',
    required: true,
    description: '1 Required, 1 Max',
    minSelections: 1,
    maxSelections: 1,
    id: 'dummy',
  },
  {
    name: 'Salad Toppings',
    required: false,
    description: 'Optional, Up to 3',
    minSelections: 0,
    maxSelections: 3,
    id: 'dummy',
  },
  {
    name: 'Utensils',
    required: true,
    description: '1 Required, 1 Max',
    minSelections: 1,
    maxSelections: 1,
    id: 'dummy',
  },
];

export const ITEMOPTIONS2: ItemOption[] = [
  {
    name: 'Crust',
    required: true,
    description: '1 Required, 1 Max',
    minSelections: 1,
    maxSelections: 1,
    id: 'dummy',
  },
  {
    name: 'Toppings',
    required: false,
    description: 'Optional, Up to 3',
    minSelections: 0,
    maxSelections: 3,
    id: 'dummy',
  },
  {
    name: 'Salad Dressing',
    required: true,
    description: '1 Required, 1 Max',
    minSelections: 1,
    maxSelections: 1,
    id: 'dummy',
  },
];

export const ITEMOPTIONS3: ItemOption[] = [
  {
    name: 'Crust',
    required: true,
    description: '1 Required, 1 Max',
    subOptions: ITEMSUBOPTIONS1,
    minSelections: 1,
    maxSelections: 1,
    id: 'dummy',
  },
  {
    name: 'Toppings',
    required: false,
    description: 'Optional, Up to 3',
    subOptions: ITEMSUBOPTIONS2,
    minSelections: 0,
    maxSelections: 3,
    id: 'dummy',
  },
];

export const ITEM1: MenuItem = {
  name: 'Wings',
  description: 'Wings are pre-breaded with a mild seasoning',
  price: 8.85,
  id: 'dummy',
};

export const ITEM2: MenuItem = {
  name: 'Eggs and Grits',
  description: 'Eggs any way and homemade Southern Grits',
  price: 12.95,
  photoUrl: 'assets/item1.png',
  options: [...ITEMOPTIONS2,],
  party: true,
  id: 'dummy',
};

export const ITEM3: MenuItem = {
  name: 'The Californian',
  description: 'Ham and Cheese Omelette with Hot Sauce and Jalapenos',
  price: 15.95,
  photoUrl: 'assets/salad.png',
  id: 'dummy',
};

export const ITEM4: MenuItem = {
  name: 'Chili Cheeseburger',
  description: 'Grass fed beef, cheddar, & chili. Add fries for $3.95',
  price: 12.00,
  photoUrl: 'assets/item2.png',
  id: 'dummy',
};

export const ITEM5: MenuItem = {
  name: 'Pizza and Salad',
  description: 'Your choice of 12" pizza and salad for 3',
  price: 25.00,
  photoUrl: 'assets/salad.png',
  options: [...ITEMOPTIONS1, ...ITEMOPTIONS1],
  id: 'dummy',
};

export const ITEM6: MenuItem = {
  name: 'Pizza Sticks and Veggies',
  description: 'Includes 4 sides of dipping, Ranch, Blue Cheese, Caesar, and Italian',
  price: 17.50,
  id: 'dummy',
};

export const ITEM7: MenuItem = {
  name: 'Large Pizza',
  description: 'Your choice of 12" pizza',
  price: 18.00,
  photoUrl: 'assets/pizza.png',
  options: [...ITEMOPTIONS3],
  id: 'dummy',
};

export const SUGGESTEDITEMS: Category = {
  name: 'Suggested Items',
  menuItems: [ITEM1, ITEM2, ITEM3],
  id: 'dummy',
};

const SUBCATEGORIES: Category[] = [
  {
    name: 'Cabernet Sauvignon',
    isSubCategory: true,
    menuItems: [
      {
        name: 'Cab One',
        description: 'Most popular',
        price: 12.80,
        id: 'dummy',
      },
      {
        name: 'Cab Two',
        description: 'The very finest!',
        price: 19.50,
        id: 'dummy',
      },
    ],
    id: 'dummy',
  },
  {
    name: 'Sauvignon Blanc',
    isSubCategory: true,
    menuItems: [
      {
        name: 'Blanco One',
        description: 'From France',
        price: 12.80,
        id: 'dummy',
      },
      {
        name: 'Blanco Two',
        description: 'California Napa Valley',
        price: 19.50,
        id: 'dummy',
      },
      {
        name: 'Blanco Three',
        description: 'On a budget',
        price: 7.50,
        id: 'dummy',
      },
    ],
    id: 'dummy',
  },
  {
    name: 'Chardonnay',
    isSubCategory: true,
    menuItems: [
      {
        name: 'Chardonnay One',
        description: 'Excellent choice',
        price: 12.80,
        id: 'dummy',
      },
    ],
    id: 'dummy',
  },
];

export const CATEGORIES: Category[] = [
  {
    name: 'Popular Items',
    menuItems: [ITEM1, ITEM2, ITEM3],
    id: 'dummy',
  },
  {
    name: 'Takeout Menu',
    menuItems: [ITEM7, ITEM5, ITEM4, ITEM6],
    id: 'dummy',
  },
  {
    name: 'Combos',
    description: 'Good Stuff!',
    menuItems: [ITEM3, ITEM4, ITEM5, ITEM6],
    id: 'dummy',
  },
  {
    name: 'Wine',
    description: 'Enjoy with your meal',
    id: 'dummy',
  },
];

export const PERIODS: Period[] = [{
  name: 'All Day',
  id: 'Period1',
  categories: [...CATEGORIES, ...SUBCATEGORIES],
  blocks: []
}];

export const RESTAURANT: Restaurant = {
  id: 'nola-hotel',
  name: 'Nola Hotel',
  address: ADDRESS,
  category: 'Pizza',
  delivery: 'Free Delivery - 45-60 mins',
  periods: [...PERIODS],
  badgeUrl: 'assets/nola-hotel.jpg',
  imageUrl: 'assets/nola-hotel.jpg',
  openTime: '0600',
  closeTime: '1000',
  taxRate: 8.0,
  serviceChargePercentage: 18.0,
  deliveryFee: 4.00,
  deliveryFeeAsPercentage: false,
  transactionFeePercentage: 0,
  transactionFeeFlat: 0,
  // standardTipPercentage: 15.0,
  menuId: 'dummy',
  orderProfileId: 'dummy',
  timezone: 'America/New_York',
  enableStripe: false,
  hideName: false,
  hideImage: false,
  deliveryWaitTimeInMinutes: 45,
  hidePricing: false,
  enableRoomCharge: true,
  languages: '',
  enablePickUp: false,
  enableRoomDelivery: true,
  enableTimeOfDelivery: true,
  enableToGoUtensils: false,
  allowDiscountCode: false,
  enablePreOrder: false,
};

export const GUEST: Guest = {
  first: 'J.',
  last: 'Doe',
  title: 'Dr.',
  roomNumber: '282',
  mobileNumber: '444-333-1234',
  peopleDining: '1',
};

export const DEFAULTDELIVERYREQUEST: DeliveryRequest = {
  location: 'my-room',
  otherRoomNumber: '',
};

export const DEFAULTDELIVERYTIME: DeliveryTime = {
  asap: true
};

export const DEFAULTCCINFO: CreditCardInfo = {
  cardNumber: ['', '', '', ''],
  expiration: ['', ''],
  billingZipCode: '',
  cvc: '',
};

export const DEFAULTPAYMENTMETHOD: PaymentMethod = {
  chargeToMyRoom: true,
  cardInfo: DEFAULTCCINFO,
};

export const DEFAULTORDER: Order = {
  restaurantId: 'nola-hotel',
  orderId: '1001',
  itemsOrdered: [],
  guest: GUEST,
  deliveryRequest: DEFAULTDELIVERYREQUEST,
  deliveryTime: DEFAULTDELIVERYTIME,
  payment: DEFAULTPAYMENTMETHOD,
  extra: {
    toGoUtensils: false,
  },
  notes: '',
};

