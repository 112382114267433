import { Component, Input, Output, EventEmitter } from '@angular/core';
import {Order, Restaurant} from '@app/shared';
import {LanguageService} from '@app/core/services/language.service';
import {RoomService} from '@app/core/services/room.service';
// import {restaurant} from "ionicons/icons";

@Component({
  selector: 'app-review-main',
  templateUrl: 'review-main.component.html',
  styleUrls: ['../review-modal.component.scss'],
})
export class ReviewMainComponent {
  @Input() order: Order;
  @Input() restaurant: Restaurant;
  @Input() reviewMain: boolean;
  @Input() invalidRoomNumber: boolean;
  @Input() requestedDeliveryTime: string;
  @Input() enableStripe: boolean;
  @Input() enableRoomCharge: boolean;
  @Input() hidePricing: string;
  @Input() enableTimeOfDelivery: boolean;
  @Input() enableToGoUtensils: boolean;
  @Input() selectedDeliveryOption: string;
  @Input() reviewStage: string; //none, confirming, confirmed
  @Output() updateReviewStage = new EventEmitter();
  @Output() changeView = new EventEmitter();
  @Output() placeOrder = new EventEmitter();
  constructor(public roomService: RoomService, public languageService: LanguageService,) {
  }

  // protected readonly restaurant = restaurant;
}
