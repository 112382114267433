import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { RestaurantPage } from './pages/restaurant/restaurant.page';

const routes: Routes = [
  // {
  //   path: 'restaurant',
  //   component: RestaurantPage,
  // },
  {
    path: 'restaurant',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule)
  // },
  {
    path: '**',
    redirectTo: 'restaurant/nola-hotel'
  }
  // {
  //   path: '',
  //   redirectTo: 'restaurant',
  //   pathMatch: 'full'
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
    // RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
    // RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
