import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import {ModalController, IonSlides, PickerController} from '@ionic/angular';
import { MenuItemModalService } from '@app/core';
import { DateTime } from 'luxon';
import {
  MenuItem,
  Restaurant,
  SUGGESTEDITEMS,
  Category,
  Order,
  ItemOrdered,
  STRIPE_PROCESSING_FEES_FLAT, STRIPE_PROCESSING_FEES_PERCENTAGE
} from '../../models';
import { ReviewModalComponent } from '../review-modal/review-modal.component';
import { ChargesInfoModalComponent } from '../charges-info-modal/charges-info-modal.component';
import {round_currency} from '@app/utils/common-utils';
import {DiscountService} from '@app/core/services/discount.service';
import {RestaurantService} from "@app/core/services/restaurant.service";
import {LanguageService} from "@app/core/services/language.service";

@Component({
  selector: 'app-order-modal',
  templateUrl: 'order-modal.component.html',
  styleUrls: ['order-modal.component.scss'],
})
export class OrderModalComponent implements OnInit {
  @Input() order: Order;
  @Input() restaurant: Restaurant;
  @ViewChild(IonSlides, { static: false }) slides: IonSlides;
  @ViewChild('ionContentOrder', { read: ElementRef }) private ionContentOrder: ElementRef;
  suggestedItems: Category = SUGGESTEDITEMS;
  tipSelected = false;
  selectedIndex = 0;
  tipSelectedAmount = 0;
  discountCode = '';
  discountCodeMessage = null;
  deliveryOptions = [{id: 'my-room', name: 'hotel_room_delivery', bg: 'bg-gray-300'}, {id: 'carry-out', name: 'pickup', bg: 'bg-gray-300'}];
  selectedDeliveryOption;

  constructor(
    private modalController: ModalController,
    private menuItemModalService: MenuItemModalService,
    private element: ElementRef,
    private pickerController: PickerController,
    public discountService: DiscountService,
    public restaurantService: RestaurantService,
    public languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.updateCharges();
    if(this.discountService.isApplicable()) {
      this.discountCode = this.discountService.getCode();
      this.discountCodeMessage = `✅  ${this.discountService.getDiscount()}% discount applied.`;
    }
    if(this.restaurant.enablePickUp && !this.restaurant.enableRoomDelivery) {
      this.selectOption(this.deliveryOptions[1]);
    }
    else if(this.restaurant.enableRoomDelivery && !this.restaurant.enablePickUp) {
      this.selectOption(this.deliveryOptions[0]);
    } else if(this.restaurant.enableRoomDelivery && this.restaurant.enablePickUp){
      this.selectOption(this.deliveryOptions[0]);
    }
  }

  selectOption(option) {
    this.order.payment.chargeToMyRoom = option.id === 'my-room';
    this.order.deliveryRequest.location = option.id;
    this.selectedDeliveryOption = option.id;

    if(option.id === this.deliveryOptions[0].id) {
      this.deliveryOptions[0].bg = 'bg-gray-400';
      this.deliveryOptions[1].bg = 'bg-gray-300';
    } else if(option.id === this.deliveryOptions[1].id) {
      this.deliveryOptions[0].bg = 'bg-gray-300';
      this.deliveryOptions[1].bg = 'bg-gray-400';
    }

    this.updateCharges();
  }

  async slideDidLoad(ev) {
    setTimeout(() => {
      this.slides.update();
    }, 0);
  }

  async removeItemFromOrder(i) {
    this.order.itemsOrdered.splice(i, 1);
    if (this.order.itemsOrdered.length === 0) {
      await this.modalController.dismiss({ clearOrder: true });
    }
    this.updateCharges();
  }

  // async viewMenuItem(menuItem: MenuItem, hidePricing: string) {
  //   const itemToAdd: ItemOrdered = await this.menuItemModalService.menuItemModal(menuItem, hidePricing);
  //   if (itemToAdd) {
  //     this.order.itemsOrdered.push(itemToAdd);
  //   }
  //   this.updateCharges();
  // }

  updateCharges() {
    let subTotal = 0;
    let discount = 0;
    // console.log('this.itemsOrdered = ', this.order.itemsOrdered);
    this.order.itemsOrdered.forEach((item) => {
      subTotal += item.count * ((+item.price) + item.totalPriceAdder);
    });
    if(this.discountService.isApplicable()) {
      discount = this.discountService.getDiscountedAmount(subTotal);
      subTotal -= discount;
    } else {
      discount = 0;
    }
    if(this.restaurant.deliveryFeeAsPercentage && this.restaurant.deliveryFee > 50.0) {
      this.restaurant.deliveryFee = 50.0;
    }
    this.restaurant.deliveryFee = +(this.restaurant.deliveryFee);
    let deliveryFee = this.restaurant.deliveryFeeAsPercentage?((this.restaurant.deliveryFee/100) * subTotal):this.restaurant.deliveryFee;
    if(this.order.deliveryRequest.location === 'carry-out') {
      deliveryFee = 0;
    }
    this.order.chargesSummary = {
      ...this.order.chargesSummary,
      discount,
      subTotal,
      tax: (this.restaurant.taxRate/100) * subTotal,
      serviceCharge: (this.restaurant.serviceChargePercentage/100) * subTotal,
      deliveryFee,
      tip: 0,
      transactionFee: 0,
      total:
        subTotal + ((this.restaurant.taxRate/100) * subTotal)
          + ((this.restaurant.serviceChargePercentage/100) * subTotal)
          + deliveryFee,
    };
    if(this.restaurant.tip && this.tipSelected) {
      this.order.chargesSummary.tip = this.tipSelectedAmount;
      this.order.chargesSummary.total += this.order.chargesSummary.tip;
    }
    /*
    let additionalTransactionFee = 0;
    if(this.restaurant.transactionFeeFlat > 0
        || this.restaurant.transactionFeePercentage > 0) {
      additionalTransactionFee = this.restaurant.transactionFeeFlat
          + ((this.restaurant.transactionFeePercentage/100) * this.order.chargesSummary.total);
      additionalTransactionFee = round_currency(additionalTransactionFee);
      this.order.chargesSummary = {
        ...this.order.chargesSummary,
        transactionFee: additionalTransactionFee,
        total: this.order.chargesSummary.total + additionalTransactionFee,
      };
    }
     */
    let finalTransactionFeeFlat = STRIPE_PROCESSING_FEES_FLAT;
    if(this.restaurant.transactionFeeFlat) {
      finalTransactionFeeFlat += (+this.restaurant.transactionFeeFlat);
    }
    let finalTransactionFeePercentage = STRIPE_PROCESSING_FEES_PERCENTAGE;
    if(this.restaurant.transactionFeePercentage) {
      finalTransactionFeePercentage += (+this.restaurant.transactionFeePercentage);
    }
    if(this.restaurant.enableStripe) {
      const newTotal = (this.order.chargesSummary.total + finalTransactionFeeFlat)
          /(1-(finalTransactionFeePercentage/100));
      const transactionFee = newTotal - this.order.chargesSummary.total;
      this.order.chargesSummary = {
        ...this.order.chargesSummary,
        transactionFee,
        total: newTotal,
      };
    }
    this.order.chargesSummary = {
      ...this.order.chargesSummary,
      tax: round_currency(+this.order.chargesSummary.tax),
      serviceCharge: round_currency(+this.order.chargesSummary.serviceCharge),
      deliveryFee: round_currency(+this.order.chargesSummary.deliveryFee),
      transactionFee: round_currency(+this.order.chargesSummary.transactionFee),
      tip: round_currency(this.order.chargesSummary.tip),
      subTotal: round_currency(+this.order.chargesSummary.subTotal),
      total: round_currency(+this.order.chargesSummary.total),
    };
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async closeModalClearOrder() {
    const reviewModalId: string = this.element.nativeElement.parentElement.parentElement.id;
    await this.modalController.dismiss({ clearOrder: true }, null, reviewModalId);
  }

  async openReviewOrderModal() {
    let deliveryTime;
    if(this.restaurantService.preOrder?.preOrderSelected) {
      deliveryTime = {
        asap: false,
        requestedTime: this.restaurantService.preOrder?.confirmedDeliveryTime,
        confirmedDeliveryTime: this.restaurantService.preOrder?.confirmedDeliveryTime,
        deliverNextDay: true
      };
    } else {
      let deliveryWaitTimeInMinutes = this.restaurant.deliveryWaitTimeInMinutes;
      if (!deliveryWaitTimeInMinutes) {
        deliveryWaitTimeInMinutes = 45;
      }
      deliveryTime = {
        ...this.order.deliveryTime,
        confirmedDeliveryTime: DateTime.now().plus({ minutes: deliveryWaitTimeInMinutes }).toFormat('h:mm a'),
        requestedTime: DateTime.now().plus({ minutes: deliveryWaitTimeInMinutes }).toFormat('h:mm a'),
        // earliestDelivery: DateTime.now().plus({ minutes: 30 }).toFormat('yyyy-LL-dd HH:mm:ss'),
        // latestDelivery: DateTime.now().plus({ hours: 4 }).toFormat('yyyy-LL-dd HH:mm:ss'),
      };
    }
    // TODO handle opening/closing times
    if(this.restaurant.enableRoomCharge) {
      this.order.payment.chargeToMyRoom = true;
    }
    else if(this.restaurant.enableStripe) {
      this.order.payment.chargeToMyRoom = false;
    }
    const reviewModal = await this.modalController.create({
      component: ReviewModalComponent,
      cssClass: ['auto-height', 'modal-backdrop-opacity'],
      componentProps: {
        order: { ...this.order, deliveryTime },
        restaurant: this.restaurant,
        selectedDeliveryOption: this.selectedDeliveryOption,
      },
    });
    reviewModal.onWillDismiss().then((data) => {
      if (data.data?.closePrevModal) {
        this.closeModalClearOrder();
      }
    });
    return await reviewModal.present();
  }

  async openChargesInfoModal() {
    console.log('In openChargesInfoModal');
    const chargesInfoModal = await this.modalController.create({
      component: ChargesInfoModalComponent,
      cssClass: ['auto-height', 'modal-backdrop-opacity'],
      componentProps: {
        order: this.order,
        restaurant: this.restaurant,
      },
    });
    return await chargesInfoModal.present();
  }

  async tipSelectionChanged() {
    if(!this.tipSelected) {
      //tip selected
      const picker = await this.pickerController.create({
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler:() => {
              this.tipSelected = false;
            }
          },
          {
            text:'Ok',
            handler:(value) => {
              if(this.restaurant.tip.isPercentage) {
                this.order.chargesSummary.tipPercentageSelected = value.tip.value;
              }
              this.tipSelectedAmount = this.restaurant.tip.isPercentage
                  ?(value.tip.value/100)*this.order.chargesSummary.subTotal
                  :value.tip.value;
              this.updateCharges();
            }
          }
        ],
        columns:[{
          name: 'tip',
          options:this.getTipOptions()
        }]
      });
      picker.columns[0].selectedIndex = this.selectedIndex;
      picker.present();
    } else {
      this.tipSelectedAmount = 0;
      delete this.order.chargesSummary.tipPercentageSelected;
      this.updateCharges();
    }
  }

  getTipOptions(){
    const options = [];
    const defaultTip = this.restaurant.tip.isPercentage?Math.round(this.restaurant.tip.value * 100):Math.round(this.restaurant.tip.value);
    for (let i = 1; i <= 100; i++) {
      if(i === defaultTip) {
        this.selectedIndex = i-1;
      }
      options.push({
        text:`${this.restaurant.tip.isPercentage?'':'$'} ${i} ${this.restaurant.tip.isPercentage?'%':''}`,
        value:i
      });
    }
    return options;
  }

  async applyDiscountCode() {
    const isValid = await this.discountService.initCode(this.restaurant.id, this.discountCode);
    if(isValid) {
      this.discountCodeMessage = `✅  ${this.discountService.getDiscount()}% discount applied.`;
    } else {
      this.discountCodeMessage = `❌  Invalid discount code.`;
    }
    this.updateCharges();
  }
}
