import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { sharedComponents } from './components';
import {StripeCardPaymentComponent} from '@app/shared/components/review-modal/stripe-card-payment/stripe-card-payment.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

@NgModule({
    declarations: [
        sharedComponents,
        StripeCardPaymentComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RouterModule,
        FilePondModule
    ],
  providers: [
    DatePipe,
  ],
  exports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    sharedComponents,
  ],
})
export class SharedModule {}
