import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
const regExp = /[a-zA-Z]/g;

@Injectable({
    providedIn: 'root'
})
export class RoomService {
    private rooms = null;
    private hasLetters = false;
    constructor(private db: AngularFireDatabase,) {
    }
    async initRooms(restaurantId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.db.object(`/rooms/${restaurantId}`)?.query.once('value').then(data => {
                const dataValues = data.val();
                if(dataValues) {
                    this.rooms = {};
                    this.hasLetters = false;
                    Object.keys(dataValues).forEach(key => {
                        const newKey = this.sanitize('' + key);
                        if(regExp.test(newKey)){
                            this.hasLetters = true;
                        }
                        this.rooms[newKey] = true;
                    });
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(() => {
                resolve(false);
            });
        });
    }
    isValidRoom(room: string) {
        if(this.rooms === null) {
            return true;
        }
        return !!this.rooms[this.sanitize(room)];
    }
    hasLettersInRoom() {
        return this.hasLetters;
    }
    sanitize(room: string) {
        const strRoom = room.replace(/ /g, '');
        return strRoom.toLowerCase();
    }
}
