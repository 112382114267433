import { Storage } from '@capacitor/storage';

export async function set(key: string, value: any, setAsJson: boolean): Promise<void> {
    // if('__proto__' in value) {
    //     delete(value.__proto__);
    // }
    await Storage.set({
        key,
        value: setAsJson?JSON.stringify(value):value
    });
}

export async function get(key: string, getAsJson: boolean): Promise<any> {
    const item = await Storage.get({ key: key });
    // console.error(`fetched ${key} : ${item.value}`);
    if(item && item.value) {
      if(getAsJson)
        {return JSON.parse(item.value);}
      else
        {return item.value;}
    }
    return null;
}

export async function remove(key: string): Promise<void> {
    await Storage.remove({
        key
    });
}
