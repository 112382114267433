// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBuCFCUT1S3DQBHkQVEv6BthV5bW_E7IBg',
    authDomain: 'trayaway-dev.firebaseapp.com',
    databaseURL: 'https://trayaway-dev-default-rtdb.firebaseio.com',
    projectId: 'trayaway-dev',
    storageBucket: 'trayaway-dev.appspot.com',
    messagingSenderId: '816811124032',
    appId: '1:816811124032:web:fef44dcd8236f7b8d586a5',
    measurementId: 'G-7NN6M55K78'
  },
  apiBaseLocation: 'https://0bnf0k9ix9.execute-api.us-east-1.amazonaws.com/dev',
  stripeKey: 'pk_test_51J2eQ2I4iEGD6dxQcxFbYItRSFhiKLD9PitrK4ewOWEEKAUUdt2LqyW1mKi77i2X6RPdFoS1l77kLvM53WmPH1O400bUMsQIQW',
  adminAuthUrl: 'https://dev-trayaway.auth.us-east-1.amazoncognito.com/login?client_id=3414ngm5rvh09ujchqiudl335r&response_type=token&scope=email+openid&redirect_uri=https://d23aywdrszs6ov.cloudfront.net/admin/auth',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
