import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';

@Injectable({
    providedIn: 'root'
})
export class DiscountService {
    private code: string;
    private discount: number;
    constructor(private db: AngularFireDatabase,) {
    }
    isApplicable() {
        return !!this.discount;
    }
    getCode() {
        return this.code;
    }
    getDiscount() {
        return this.discount;
    }
    async initCode(restaurantId: string, code: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.code = code;
            const applyCode = code.toLowerCase();
            this.db.object(`/codes/${restaurantId}/${applyCode}`)?.query.once('value').then(data => {
                this.discount = +data.val();
                resolve(this.discount > 0);
            }).catch(() => {
                resolve(false);
            });
        });
    }
    getDiscountedAmount(price: number) {
        if(this.discount) {
            return (this.discount/100)*price;
        }
        return 0;
    }
    getDiscountedPrice(price: number) {
        if(this.discount) {
            return ((100 - this.discount)/100)*price;
        }
        return price;
    }
}
