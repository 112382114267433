import {Component, Input, AfterViewInit, ElementRef, OnInit} from '@angular/core';
import {ModalController, LoadingController, ToastController} from '@ionic/angular';
import {
  trigger,
  style,
  transition,
  state,
  animate,
} from '@angular/animations';
import { Restaurant, DeliveryRequest, Order, DeliveryTime, PaymentMethod, CreditCardInfo } from '../../models';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import {RestaurantService} from '@app/core/services/restaurant.service';
import {StripeService} from '@app/core/services/stripe.service';
import {LanguageService} from "@app/core/services/language.service";
import {RoomService} from "@app/core/services/room.service";

@Component({
  selector: 'app-review-modal',
  templateUrl: 'review-modal.component.html',
  styleUrls: ['review-modal.component.scss'],
  animations: [
    trigger('fadeInOutAnimation', [
      state('show', style({ height: '*', opacity: 1 })),
      state('hide', style({ height: '0', opacity: 0 })),
      transition('show <=> hide', [animate('250ms ease-in-out')]),
    ]),
  ]
})
export class ReviewModalComponent implements OnInit, AfterViewInit {
  @Input() order: Order;
  @Input() restaurant: Restaurant;
  @Input() selectedDeliveryOption;
  reviewMain = true;
  reviewDeliveryOptions = false;
  reviewDeliveryTime = false;
  reviewPayment = false;
  reviewToGoUtensils = false;
  enterNewCard = false;
  viewInitialized = false;
  reviewStage = 'none';
  invalidRoomNumber = false;
  loading;
  // deliveryOptions = [{id: 'carry-out', name: 'Pickup'},{id: 'my-room', name: 'Hotel Room Delivery'}];
  // selectedDeliveryOption;

  constructor(
    private modalController: ModalController,
    private element: ElementRef,
    private loadingController: LoadingController,
    private restaurantService: RestaurantService,
    public toastController: ToastController,
    private stripeService: StripeService,
    public languageService: LanguageService,
    private roomService: RoomService,
  ) {}

  ngOnInit() {
    // if(this.restaurant.enableRoomDelivery === undefined) {
    //   this.restaurant.enableRoomDelivery = true;
    // }
    // if(this.restaurant.enablePickUp && !this.restaurant.enableRoomDelivery) {
    //   this.selectOption(this.deliveryOptions[0]);
    // }
    // else if(this.restaurant.enableRoomDelivery && !this.restaurant.enablePickUp) {
    //   this.selectOption(this.deliveryOptions[1]);
    // }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInitialized = true;
    }, 1000);
  }

  // selectOption(option) {
  //   this.order.deliveryRequest.location = option.id;
  //   this.selectedDeliveryOption = option.id;
  // }

  changeView(newView: string) {
    // 'main', 'delivery', 'time', 'payment', 'card'
    switch (newView) {
      case 'main':
        this.reviewMain = true;
        this.reviewDeliveryOptions = false;
        this.reviewDeliveryTime = false;
        this.reviewPayment = false;
        this.enterNewCard = false;
        this.reviewToGoUtensils = false;
        this.reviewStage = 'none';
        break;
      case 'delivery':
        this.reviewMain = false;
        this.reviewDeliveryOptions = true;
        this.reviewDeliveryTime = false;
        this.reviewPayment = false;
        this.enterNewCard = false;
        this.reviewToGoUtensils = false;
        this.reviewStage = 'none';
        break;
      case 'time':
        this.reviewDeliveryOptions = false;
        if(this.restaurantService.preOrder?.preOrderSelected) {
          this.reviewMain = true;
          this.reviewDeliveryTime = false;
        } else {
          this.reviewMain = false;
          this.reviewDeliveryTime = true;
        }
        this.reviewPayment = false;
        this.enterNewCard = false;
        this.reviewToGoUtensils = false;
        this.reviewStage = 'none';
        break;
      case 'payment':
        this.reviewMain = false;
        this.reviewDeliveryOptions = false;
        this.reviewDeliveryTime = false;
        this.reviewPayment = true;
        this.enterNewCard = false;
        this.reviewToGoUtensils = false;
        this.reviewStage = 'none';
        break;
      case 'card':
        this.reviewMain = false;
        this.reviewDeliveryOptions = false;
        this.reviewDeliveryTime = false;
        this.reviewPayment = false;
        this.enterNewCard = true;
        this.reviewToGoUtensils = false;
        this.reviewStage = 'none';
        break;
      case 'to-go-utensils':
        this.reviewMain = false;
        this.reviewDeliveryOptions = false;
        this.reviewDeliveryTime = false;
        this.reviewPayment = false;
        this.enterNewCard = false;
        this.reviewToGoUtensils = true;
        this.reviewStage = 'none';
        break;
    }
  }

  saveDeliveryUpdates(localDeliveryRequest: DeliveryRequest) {
    this.order.deliveryRequest = { ...localDeliveryRequest };
    this.changeView('main');
  }

  saveTimeUpdates(localDeliveryTime: DeliveryTime) {
    this.order.deliveryTime = { ...localDeliveryTime };
    this.changeView('main');
  }

  saveToGoUtensilsUpdates(toGoUtensils: boolean) {
    this.order.extra = { toGoUtensils };
    this.changeView('main');
  }

  savePaymentUpdates(localPaymentInfo: PaymentMethod) {
    this.order.payment = { ...localPaymentInfo };
    if (localPaymentInfo.chargeToMyRoom) {
      // this.order.payment = { ...localPaymentInfo };
      this.changeView('main');
    } else {
      // this.changeView('card');
      this.changeView('main');
    }
  }

  saveNewCard(newCard: CreditCardInfo) {
    this.order.payment.cardInfo = { ...newCard };
    this.changeView('main');
  }

  async closeModalWithId() {
    const reviewModalId: string = this.element.nativeElement.parentElement.parentElement.id;
    await this.modalController.dismiss({ closePrevModal: true }, null, reviewModalId);
  }

  async updateReviewStage(newStage: string) {
    if(newStage === 'confirmed') {
      const roomNumber = this.order.guest.roomNumber?.trim();
      const isValidRoom = this.roomService.isValidRoom(roomNumber);
      if(!isValidRoom) {
        this.invalidRoomNumber = true;
        return;
      }
    }
    this.invalidRoomNumber = false;
    this.reviewStage = newStage;
  }

  async placeOrder() {
    setTimeout(async () => {
      let responseData;
      if(this.order.deliveryRequest?.location === 'my-room') {
        if(this.order.guest.roomNumber?.trim()?.length < 1) {
          const toast = await this.toastController.create({
            message: 'Enter Room #.',
            duration: 2000
          });
          await toast.present();
          return;
        }

        if(this.order.guest.peopleDining?.trim()?.length < 1) {
          const toast = await this.toastController.create({
            message: 'Enter # of people dining.',
            duration: 2000
          });
          await toast.present();
          return;
        }
      }
      if(this.order.guest.last?.trim()?.length < 1) {
        const toast = await this.toastController.create({
          message: 'Enter Name.',
          duration: 2000
        });
        await toast.present();
        return;
      }
      if(this.reviewStage !== 'confirmed') {
        this.reviewStage = 'confirming';
        return;
      }
      try {
        if(this.order.payment.chargeToMyRoom) {
          await this.presentLoading();
          responseData = await this.restaurantService.postOrder(this.order);
          await this.loading.dismiss();
        } else {
          await this.presentLoading();
          const tokenResult = await this.stripeService.getToken();
          if(!tokenResult.error) {
            console.log(tokenResult.token.id);
            this.order.payment.stripeToken = tokenResult.token;
            responseData = await this.restaurantService.postOrder(this.order);
          } else {
            this.reviewStage = 'none';
          }
          await this.loading.dismiss();
        }
      } catch (e) {
        this.reviewStage = 'none';
        console.error(e);
        await this.loading.dismiss();
      }

      if(responseData && responseData.status !== 'error') {
        const confirmModal = await this.modalController.create({
          component: ConfirmationModalComponent,
          componentProps: {
            order: this.order,
            restaurant: this.restaurant,
          },
        });
        confirmModal.onWillDismiss().then((data) => {
          if (data.data?.closePrevModal) {
            this.closeModalWithId();
          }
        });
        return await confirmModal.present();
      } else {
        this.reviewStage = 'none';
        let errorMesssage = 'Error while placing order.';
        if(responseData?.reason === 'STRIPE_SETUP_INCOMPLETE') {
          errorMesssage = 'Error while placing order as payment setup not completed.';
        }
        const toast = await this.toastController.create({
          message: errorMesssage,
          duration: 2000
        });
        await toast.present();
      }
    }, 0);
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: this.languageService.translate('placing_order'),
      // duration: 3000
    });
    await this.loading.present();
    // await loading.onDidDismiss();
  }
}
