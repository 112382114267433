import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ItemOrderedOption } from '@app/shared';

@Component({
  selector: 'app-options-modal',
  templateUrl: 'options-modal.component.html',
  styleUrls: ['options-modal.component.scss'],
})
export class OptionsModalComponent implements OnInit {
  @Input() option: ItemOrderedOption;
  continueEnabled = false;

  constructor(
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    this.continueEnabled = this.enableContinue();
  }

  async subOptionClick(i) {
    if (this.option.subOptions[i].selected) {
      // deselecting an option
      this.option.subOptions[i].selected = false;
    } else {
      if (this.option.maxSelections === 1) {
        let j = 0;
        this.option.subOptions.forEach((subOption) => {
          this.option.subOptions[j].selected = false;
          j++;
        });
        this.option.subOptions[i].selected = true;
      } else {
        const numberSelected = this.option.subOptions.filter((v) => v.selected).length;
        if (numberSelected < this.option.maxSelections) {
          // allow this selection
          this.option.subOptions[i].selected = true;
        } else {
          // do nothing - user must deselect an option 1st
        }
      }
    }
    this.option.subOptionsText = this.updateSubOptionText();
    this.continueEnabled = this.enableContinue();
  }

  updateSubOptionText(): string {
    let newSubOptionsText = '';
    this.option.subOptions.forEach((subOption) => {
      if (subOption.selected) {
        if (newSubOptionsText !== '') {
          newSubOptionsText = `${newSubOptionsText}, `;
        }
        newSubOptionsText = `${newSubOptionsText}${subOption.translatedName || subOption.name}`;
      }
    });
    return newSubOptionsText;
  }

  enableContinue(): boolean {
    if (this.option.minSelections === 0) {
      return true;
    } else {
      const hasSelected = this.option.subOptions.filter((v) => v.selected);
      if (hasSelected.length >= this.option.minSelections) {
        return true;
      }
      return false;
    }
    return false;
  }

  async continueClick() {
    this.option.selectionCriteriaMet = true;
    await this.modalController.dismiss({
      subOptions: this.option.subOptions,
    });
  }
}
