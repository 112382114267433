import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DatePipe} from '@angular/common';
import {DateTime} from 'luxon';
import {LanguageService} from "@app/core/services/language.service";

@Component({
  selector: 'app-pre-order-modal',
  templateUrl: 'pre-order-modal.component.html',
  styleUrls: ['pre-order-modal.component.scss'],
})
export class PreOrderModalComponent implements OnInit {
  // @Input() language: string;
  arrOptions: string[] = ['order_for_today', 'pre_order_for_tomorrow'];
  // arrOptionsSpanish: string[] = ['PEDIDO PARA HOY', 'PEDIDO PARA MAÑANA'];
  // deliveryTime = 'Delivery Time';
  // deliveryTimeSpanish = 'El tiempo de entrega';
  // confirmText = 'CONFIRM';
  // confirmTextSpanish = 'CONFIRMAR';
  selectedOption: string;
  requestedDeliveryTime: string;
  confirmedDeliveryTime?: string;
  minuteValues = '0,15,30,45';
  constructor(private modalController: ModalController,
              private datePipe: DatePipe,
              public languageService: LanguageService,
  ) {}

  ngOnInit() {
    const remainder = 15 - (DateTime.now().minute % 15);
    this.requestedDeliveryTime = DateTime.now().plus({ minutes: (60+remainder+(60*24)) }).toUTC().toString();
    this.updateRequestedDeliveryTime();
  }

  selectOption(option: string) {
    this.selectedOption = option;
  }

  updateRequestedDeliveryTime() {
    this.confirmedDeliveryTime = this.datePipe.transform(this.requestedDeliveryTime, 'h:mm a');
  }

  preOrderSelected() {
    return this.selectedOption === 'pre_order_for_tomorrow';
  }

  confirm() {
    this.modalController.dismiss({
      preOrderSelected: this.preOrderSelected(),
      confirmedDeliveryTime: this.confirmedDeliveryTime,
    });
  }
}
