import { Injectable } from '@angular/core';
import { MenuItemStyleUpdates, HomePageStyleUpdates } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class ScrollingContentService {

  constructor() {}

  updateHomeStyles(
    totalScrollY: number,
    scrollDirection: string,
    headerBadgeSlotIn: number,
    badgeToBadgeViewOffset: number,
    contentHeaderTopSpace: number,
    categoryListSizes: number[],
    contentBadgeSize: number,
    maxHeaderBadgeSize: number,
  ): HomePageStyleUpdates {
    let catNameIndexInHeader = -1;
    let i = 0;
    categoryListSizes.forEach((catListSize) => {
      if (totalScrollY > catListSize) {
        catNameIndexInHeader = i;
      }
      i++;
    });
    let textRowOpacity;
    if (totalScrollY > 0 && totalScrollY < contentHeaderTopSpace + contentBadgeSize) {
      textRowOpacity = 1 - (totalScrollY / (contentHeaderTopSpace + contentBadgeSize)) / 2;
    } else {
      textRowOpacity = totalScrollY <= 0 ? 1 : 0.5;
    }
    const belowModifyZone = totalScrollY < (contentHeaderTopSpace + contentBadgeSize + badgeToBadgeViewOffset);
    const aboveModifyZone = totalScrollY > (contentHeaderTopSpace + contentBadgeSize + badgeToBadgeViewOffset + headerBadgeSlotIn);
    if (belowModifyZone || aboveModifyZone) {
      // do not alter headerBadge properties
      if (scrollDirection === 'up') {
        if (aboveModifyZone) {
          return {
            headerBadgeMarginTop: 0,
            headerBadgeOpacity: 1,
            headerBadgeSize: `${maxHeaderBadgeSize}px`,
            catNameIndexInHeader,
            textRowOpacity,
          };
        } else {
          return {
            headerBadgeMarginTop: headerBadgeSlotIn,
            headerBadgeOpacity: 0,
            headerBadgeSize: `0px`,
            catNameIndexInHeader,
            textRowOpacity,
          };
        }
      } else {
        if (belowModifyZone) {
          return {
            headerBadgeMarginTop: headerBadgeSlotIn,
            headerBadgeOpacity: 0,
            headerBadgeSize: `0px`,
            catNameIndexInHeader,
            textRowOpacity,
          };
        } else {
          return {
            headerBadgeMarginTop: 0,
            headerBadgeOpacity: 1,
            headerBadgeSize: `${maxHeaderBadgeSize}px`,
            catNameIndexInHeader,
            textRowOpacity,
          };
        }
      }
    } else {
      const headerBadgeMarginTop = (contentHeaderTopSpace + contentBadgeSize + badgeToBadgeViewOffset + headerBadgeSlotIn) - totalScrollY;
      const headerBadgeOpacity = (headerBadgeSlotIn - headerBadgeMarginTop) / headerBadgeSlotIn;
      const headerBadgeSize = `${Math.min(maxHeaderBadgeSize, (headerBadgeSlotIn - headerBadgeMarginTop))}px`;
      return {
        headerBadgeMarginTop,
        headerBadgeOpacity,
        headerBadgeSize,
        catNameIndexInHeader,
        textRowOpacity,
      };
    }
  }

  updateMenuItemStyles(
    totalScrollY: number,
    collapseHeaderHeight: number,
    initialHeaderHeight: number,
    maxContentScrollUp: number,
    scrollDirection: string,
  ): MenuItemStyleUpdates {
    const headerHeight1 = Math.min(initialHeaderHeight, initialHeaderHeight + totalScrollY);
    const headerHeight2 = maxContentScrollUp > initialHeaderHeight
      ? Math.max(collapseHeaderHeight, headerHeight1)
      : Math.max(collapseHeaderHeight, headerHeight1, (initialHeaderHeight - maxContentScrollUp));
    const headerBackdropFilter = `blur(${((initialHeaderHeight - headerHeight2) * 5 / initialHeaderHeight)}px)`;
    const nameInHeader = scrollDirection === 'up'? headerHeight2 <= collapseHeaderHeight : headerHeight2 <= collapseHeaderHeight;
    const topGridMarginTop = nameInHeader
      ? this.contentMarginTop(totalScrollY, initialHeaderHeight - collapseHeaderHeight, maxContentScrollUp)
      : '0px';
    return {
      headerHeight: `${headerHeight2}px`,
      headerBackdropFilter,
      nameInHeader,
      topGridMarginTop,
    };
  }

  contentMarginTop(
    totalScrollY: number,
    headerHeightDelta: number,
    maxContentScrollUp: number,
  ): string | null {
    if (-1 * totalScrollY > headerHeightDelta && -1 * totalScrollY < maxContentScrollUp) {
      return `${totalScrollY + headerHeightDelta}px`;
    }
    return null;
  }
}
