import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Order, Restaurant } from '@app/shared';

@Component({
  selector: 'app-charges-info-modal',
  templateUrl: 'charges-info-modal.component.html',
  styleUrls: ['charges-info-modal.component.scss'],
})
export class ChargesInfoModalComponent implements OnInit {
  @Input() order: Order;
  @Input() restaurant: Restaurant;

  constructor(
    private modalController: ModalController,
  ) {}

  ngOnInit() {}

  async done() {
    await this.modalController.dismiss();
  }
}
