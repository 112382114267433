import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ItemOption } from '@app/shared';
import {LanguageService} from "@app/core/services/language.service";

@Component({
  selector: 'app-help-modal',
  templateUrl: 'help-modal.component.html',
  styleUrls: ['help-modal.component.scss'],
})
export class HelpModalComponent implements OnInit {
  @Input() itemOption: ItemOption;
  @Input() contactNumber: string;
  // helpNames = ['Contact Us', 'Cancel Order', 'Dismiss'];
  // helpNames = ['Contact Us', 'Dismiss'];
  helpNames = ['dismiss'];

  constructor(
    private modalController: ModalController,
    public languageService: LanguageService,
  ) {}

  ngOnInit() {
  }

  async itemClick(i: number) {
    switch (i) {
      // case 0:
      //   // window.open('https://www.trayaway.com/');
      //   const contactUsModal = await this.modalController.create({
      //     component: ContactUsModalComponent,
      //     cssClass: ['auto-height', 'modal-backdrop-opacity'],
      //     componentProps: {
      //     },
      //   });
      //   contactUsModal.onWillDismiss().then((data) => {
      //     if (data.data?.closePrevModal) {
      //     }
      //   });
      //   await contactUsModal.present();
      //   break;
      case 0:
      //   console.log('Cancel order');
      //   break;
      // case 2:
        // Clear all modals and order
        await this.modalController.dismiss({
          closePrevModal: true
        });
        break;
    }
  }
}
