import md5 from 'md5';

export const hhmmToMinutes = (hhmm: string): number => {
    const splits = hhmm.split(':');
    return (+splits[0]) * 60 + (+splits[1]);
};

export const minutesToHHMM = (minute: number): string => {
    const hours   = Math.floor(minute / 60);
    const minutes = minute - (hours * 60);
    return (hours < 10?'0'+hours:hours) +
        ':' +
        (minutes < 10?'0'+minutes:minutes);
};
export const minutesToHHMMAA = (minute: number): string => {
    let hours   = Math.floor(minute / 60);
    const minutes = minute - (hours * 60);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = ((hours + 11) % 12 + 1);
    return (hours < 10?'0'+hours:hours) +
        ':' +
        (minutes < 10?'0'+minutes:minutes) +
        ampm;
};

export const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
        hours = '00';
    }
    if (modifier.toLowerCase() === 'pm') {
        hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
};

export const downloadStringAsFile = (str, fileName) => {
    const blob = new Blob([str]);
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

export const round_currency = (price) => Math.round((price + Number.EPSILON) * 100) / 100;

export const SST = 'X6CRqSCtJ!99';
export const verification = (order, token, time) => {
    order = {
        ...order,
        sst: md5(SST),
        cst: md5(token),
        time,
    };
    return md5(JSON.stringify(order));
};
