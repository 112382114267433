import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { Order, CreditCardInfo } from '@app/shared';

@Component({
  selector: 'app-card-entry',
  templateUrl: 'cardEntry.component.html',
  styleUrls: ['../review-modal.component.scss'],
})
export class CardEntryComponent implements OnInit, AfterViewInit {
  @Input() order: Order;
  @Output() mainReview = new EventEmitter();
  @Output() saveNewCard = new EventEmitter();
  @ViewChild('cardA', { read: ElementRef }) private cardA: ElementRef;
  @ViewChild('cardB', { read: ElementRef }) private cardB: ElementRef;
  @ViewChild('cardC', { read: ElementRef }) private cardC: ElementRef;
  @ViewChild('cardD', { read: ElementRef }) private cardD: ElementRef;
  @ViewChild('expA', { read: ElementRef }) private expA: ElementRef;
  @ViewChild('expB', { read: ElementRef }) private expB: ElementRef;
  @ViewChild('cvc', { read: ElementRef }) private cvc: ElementRef;
  @ViewChild('zip', { read: ElementRef }) private zip: ElementRef;
  ccForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.ccForm = this.fb.group({
      cardNumberA: [
        this.order.payment.cardInfo.cardNumber[0], this.buildValidator(4, 4)
      ],
      cardNumberB: [
        this.order.payment.cardInfo.cardNumber[1], this.buildValidator(4, 4)
      ],
      cardNumberC: [
        this.order.payment.cardInfo.cardNumber[2], this.buildValidator(4, 4)
      ],
      cardNumberD: [
        this.order.payment.cardInfo.cardNumber[3], this.buildValidator(4, 4)
      ],
      expirationA: [
        this.order.payment.cardInfo.expiration[0], this.buildValidatorValue(1, 12)
      ],
      expirationB: [
        this.order.payment.cardInfo.expiration[1], this.buildValidatorValue(21, 31)
      ],
      cvc: [
        this.order.payment.cardInfo.cvc, this.buildValidator(3, 3)
      ],
      billingZipCode: [
        this.order.payment.cardInfo.billingZipCode, this.buildValidator(5)
      ],
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cardA.nativeElement.setFocus();
    }, 250);
  }

  buildValidator(min: number, max?: number): ValidatorFn {
    return Validators.compose([Validators.minLength(min), Validators.maxLength(max), Validators.required]);
  }

  buildValidatorValue(min: number, max?: number): ValidatorFn {
    if (max) {
      return Validators.compose(
        [Validators.min(min), Validators.max(max), Validators.minLength(2), Validators.maxLength(2), Validators.required]
      );
    }
    return Validators.compose(
      [Validators.min(min), Validators.minLength(2), Validators.maxLength(2),  Validators.required]
    );
  }

  focusCCNumber(i: number) {
    switch (i) {
      case 0:
        this.ccForm.patchValue({ cardNumberA: '' });
        break;
      case 1:
        if (this.ccForm.controls.cardNumberA.value.length < 4) {
          this.ccForm.patchValue({ cardNumberA: '' });
          this.cardA.nativeElement.setFocus();
        }
        this.ccForm.patchValue({ cardNumberB: '' });
        break;
      case 2:
        if (this.ccForm.controls.cardNumberB.value.length < 4) {
          this.ccForm.patchValue({ cardNumberB: '' });
          if (this.ccForm.controls.cardNumberA.value.length < 4) {
            this.ccForm.patchValue({ cardNumberA: '' });
            this.cardA.nativeElement.setFocus();
          } else {
            this.cardB.nativeElement.setFocus();
          }
        }
        this.ccForm.patchValue({ cardNumberC: '' });
        break;
      case 3:
        if (this.ccForm.controls.cardNumberC.value.length < 4) {
          this.ccForm.patchValue({ cardNumberC: '' });
          if (this.ccForm.controls.cardNumberB.value.length < 4) {
            this.ccForm.patchValue({ cardNumberB: '' });
            if (this.ccForm.controls.cardNumberA.value.length < 4) {
              this.ccForm.patchValue({ cardNumberA: '' });
              this.cardA.nativeElement.setFocus();
            } else {
              this.cardB.nativeElement.setFocus();
            }
          } else {
            this.cardC.nativeElement.setFocus();
          }
        }
        this.ccForm.patchValue({ cardNumberD: '' });
        break;
    }
  }

  focusCCExp(i: number) {
    switch (i) {
      case 0:
        this.ccForm.patchValue({ expirationA: '' });
        break;
      case 1:
        if (this.ccForm.controls.expirationA.value < 1 || this.ccForm.controls.expirationA.value > 12) {
          this.ccForm.patchValue({ expirationA: '' });
          this.cardA.nativeElement.setFocus();
        }
        this.ccForm.patchValue({ expirationB: '' });
        break;
    }
  }

  focusCCCVC() {
    this.ccForm.patchValue({ cvc: '' });
  }

  focusCCZip() {
    this.ccForm.patchValue({ zip: '' });
  }

  changeCCNumber(i: number) {
    this.ccNumberInvalid();
    switch (i) {
      case 0:
        if (this.ccForm.controls.cardNumberA.valid && this.ccForm.controls.cardNumberB.invalid) {
          this.focusCCNumber(1);
          this.cardB.nativeElement.setFocus();
        }
        break;
      case 1:
        if (this.ccForm.controls.cardNumberB.valid && this.ccForm.controls.cardNumberC.invalid) {
          this.focusCCNumber(2);
          this.cardC.nativeElement.setFocus();
        }
        break;
      case 2:
        if (this.ccForm.controls.cardNumberC.valid && this.ccForm.controls.cardNumberD.invalid) {
          this.focusCCNumber(3);
          this.cardD.nativeElement.setFocus();
        }
        break;
      case 3:
        if (this.ccForm.controls.cardNumberD.valid && this.ccForm.controls.expirationA.invalid) {
          this.focusCCExp(0);
          this.expA.nativeElement.setFocus();
        }
        break;
    }
  }

  changeCCExp(i: number) {
    switch (i) {
      case 0:
        if (this.ccForm.controls.expirationA.valid && this.ccForm.controls.expirationB.invalid) {
          this.focusCCExp(1);
          this.expB.nativeElement.setFocus();
        }
        break;
      case 1:
        if (this.ccForm.controls.expirationB.valid && this.ccForm.controls.cvc.invalid) {
          this.focusCCCVC();
          this.cvc.nativeElement.setFocus();
        }
        break;
    }
  }

  changeCVC() {
    if (this.ccForm.controls.cvc.valid && this.ccForm.controls.billingZipCode.invalid) {
      this.focusCCZip();
      this.zip.nativeElement.setFocus();
    }
  }

  saveNewCC() {
    const ccInfo: CreditCardInfo = {
      cardNumber: [
        this.ccForm.controls.cardNumberA.value,
        this.ccForm.controls.cardNumberB.value,
        this.ccForm.controls.cardNumberC.value,
        this.ccForm.controls.cardNumberD.value,
      ],
      expiration: [
        this.ccForm.controls.expirationA.value,
        this.ccForm.controls.expirationB.value
      ],
      cvc: this.ccForm.controls.cvc.value,
      billingZipCode: this.ccForm.controls.billingZipCode.value,
    };
    this.saveNewCard.emit(ccInfo);
  }

  ccNumberInvalid() {
    const cardNumberA = this.ccForm.get('cardNumberA');
    const borderValueA = (cardNumberA && cardNumberA.touched && cardNumberA.invalid)
      ? 'solid 1px var(--ion-color-danger)'
      : 'none';
    this.cardA.nativeElement.style.setProperty('border-left', borderValueA);

    const cardNumberB = this.ccForm.get('cardNumberB');
    const borderValueB = (cardNumberB && cardNumberB.touched && cardNumberB.invalid)
      ? 'solid 1px var(--ion-color-danger)'
      : 'none';
    this.cardB.nativeElement.style.setProperty('border-left', borderValueB);

    const cardNumberC = this.ccForm.get('cardNumberC');
    const borderValueC = (cardNumberC && cardNumberC.touched && cardNumberC.invalid)
      ? 'solid 1px var(--ion-color-danger)'
      : 'none';
    this.cardC.nativeElement.style.setProperty('border-left', borderValueC);

    const cardNumberD = this.ccForm.get('cardNumberD');
    const borderValueD = (cardNumberD && cardNumberD.touched && cardNumberD.invalid)
      ? 'solid 1px var(--ion-color-danger)'
      : 'none';
    this.cardD.nativeElement.style.setProperty('border-left', borderValueD);
  }
  // TODO complete validation of remaining form controls
}
