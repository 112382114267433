import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Order, DeliveryRequest } from '@app/shared';

@Component({
  selector: 'app-delivery-options',
  templateUrl: 'delivery-options.component.html',
  styleUrls: ['../review-modal.component.scss'],
})
export class DeliveryOptionsComponent implements OnInit {
  @Input() order: Order;
  @Output() mainReview = new EventEmitter();
  @Output() saveDeliveryUpdates = new EventEmitter();
  @ViewChild('roomNumberInput', { read: ElementRef }) private roomNumberInput: ElementRef;
  localDeliveryRequest: DeliveryRequest;
  otherRoomToDeliver = '';

  ngOnInit() {
    this.localDeliveryRequest = { ...this.order.deliveryRequest };
  }

  deliveryRequest(deliveryLocation: string) {
    // 'my-room', 'another-room', or 'carry-out'
    this.localDeliveryRequest.location = deliveryLocation;
    if (deliveryLocation === 'another-room') {
      this.roomNumberInput.nativeElement.setFocus();
    }
  }

  updateOtherRoomNumber() {
    this.localDeliveryRequest.otherRoomNumber = this.otherRoomToDeliver;
  }
}
