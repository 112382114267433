import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Category } from '@app/shared';

@Component({
  selector: 'app-categories-modal',
  templateUrl: 'categories-modal.component.html',
  styleUrls: ['categories-modal.component.scss'],
})
export class CategoriesModalComponent {
  @Input() categories: Category[];
  @Input() catNameIndexInHeader: number;

  constructor(
    private modalController: ModalController,
  ) {}

  async gotoCategory(categoryIndex) {
    await this.modalController.dismiss({ categoryIndex });
  }
}
