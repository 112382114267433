import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MenuItem, ItemOrdered } from '@app/shared';
import {DiscountService} from '@app/core/services/discount.service';
import {RestaurantService} from "@app/core/services/restaurant.service";

@Component({
  selector: 'app-menu-item',
  templateUrl: 'menu-item.component.html',
  styleUrls: ['menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  @Input() item: MenuItem | ItemOrdered;
  @Input() hidePricing: string;
  @Input() parentSection: string;  // 'home':MenuItem, 'orderAdd':MenuItem, 'orderList':ItemOrdered
  @Output() addItemToOrder = new EventEmitter();
  // discountApplicable = false;

  constructor(
    private modalController: ModalController,
    public discountService: DiscountService,
  ) {
    // if(this.discountService.isApplicable()) {
    //   this.discountApplicable = true;
    // }
  }

  ngOnInit() {
    // if (this.item.available === undefined) {
    //   this.item.available = true;
    // }
    // if (this.parentSection === 'orderList' && this.item.options) {
    //   this.item.options.forEach((option) => {
    //     let subOptionText = '';
    //     option.subOptions.forEach((subOption) => {
    //       if (subOption.selected) {
    //         subOptionText = subOptionText === ''
    //           ? subOptionText = subOption.name
    //           : subOptionText = `${subOptionText}, ${subOption.name}`;
    //       }
    //     });
    //     if (subOptionText !== '') {
    //       this.subOptionsTextArray.push(subOptionText);
    //     }
    //   });
    // }
  }

  async gotoCategory(categoryIndex) {
    await this.modalController.dismiss({ categoryIndex });
  }

  shouldShowPricing() {
    return (this.hidePricing !== 'true' || ((+this.item.price) > 0));
  }
}
