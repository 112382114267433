import {AngularFireDatabase, AngularFireList, AngularFireObject} from '@angular/fire/database';
import {Injectable, NgZone} from '@angular/core';
import {Restaurant} from '@app/shared';
import axios from 'axios';
import {environment} from '../../../environments/environment';
import {first, map} from 'rxjs/operators';
import {hhmmToMinutes, minutesToHHMMAA, verification} from '@app/utils/common-utils';
import {get, remove, set} from '@app/core/services/storage.service';
import {BehaviorSubject} from 'rxjs';
import {NavController} from '@ionic/angular';
import {nanoid} from 'nanoid';

@Injectable({
    providedIn: 'root'
})
export class RestaurantService {
    static days = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'];
    todaysDayOfWeek: string = RestaurantService.days[new Date(new Date()).getDay()];
    restaurant: Restaurant = null;//RESTAURANT;
    selectedCategoryIndex = 0;
    selectedPeriodIndex = 0;
    todayMinutesBlocks: MinutesBlock[][] = [];
    adminInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
    adminRestaurant: Restaurant = null;
    //language selected through url params or language modal
    language: string = null;
    preOrder: any = null;

    private dbPath = '/restaurants';
    private readonly restaurantsRef: AngularFireList<Restaurant> = null;

    constructor(private db: AngularFireDatabase,
                private ngZone: NgZone,
                private navController: NavController,) {
        this.restaurantsRef = db.list(this.dbPath);
        // this.adminInfo$.subscribe(async (adminInfo) => {
        //     this.adminRestaurant = await this.retrieveRestaurant(adminInfo.restaurantId);
        // });
    }

    getLanguageProperty(propertyName: string) {
        return this.language?`${this.language}-${propertyName}`:propertyName;
    }

    async retrieveAdminRestaurant() {
        await this.retrieveAdminInfo();
        const adminInfo = this.adminInfo$.getValue();
        this.adminRestaurant = await this.retrieveRestaurant(adminInfo?.restaurantId);
    }

    async retrieveAdminInfo() {
        const adminInfo = await this.postAdminInfo(null);
        if(adminInfo) {
            this.adminInfo$.next(adminInfo.info);
        }
    }

    // getAll(): AngularFireList<Restaurant> {
    //     return this.restaurantsRef;
    // }

    get(id: string): AngularFireObject<Restaurant> {
        return this.db.object(this.dbPath + '/' + id);
    }

    async retrieveRestaurant(restaurantId): Promise<Restaurant> {
        return new Promise((resolve, reject) => {
            if(restaurantId) {
                this.get(restaurantId).snapshotChanges().pipe(
                    first(),
                    map(changes => {
                            const k = 0;
                            // @ts-ignore
                            return changes.payload.val();
                        }
                    )
                ).subscribe(data => {
                    this.restaurant = data;
                    if(this.restaurant) {
                        this.todaysDayOfWeek = RestaurantService.days[
                            new Date(new Date().toLocaleString('en-US',
                                {timeZone: this.restaurant.timezone})).getDay()];
                        this.todayMinutesBlocks = [];
                        const yesterdaysDate = new Date(new Date().toLocaleString('en-US',
                            {timeZone: this.restaurant.timezone}));
                        yesterdaysDate.setDate(yesterdaysDate.getDate() - 1);
                        const yesterdaysDayOfWeek = RestaurantService.days[yesterdaysDate.getDay()];

                        if(this.restaurant?.periods) {
                            this.restaurant?.periods?.forEach((period, index, array) => {
                                this.todayMinutesBlocks.push([]);
                                period?.blocks?.forEach((block) => {
                                    if(block.day === this.todaysDayOfWeek) {
                                        const minutesBlock: MinutesBlock = new MinutesBlock();
                                        minutesBlock.startMinutes = hhmmToMinutes(block.start);
                                        minutesBlock.endMinutes = minutesBlock.startMinutes + block.duration;
                                        if(minutesBlock.endMinutes > 1440) {
                                            minutesBlock.endMinutes = 1440;
                                        }
                                        this.todayMinutesBlocks[index].push(minutesBlock);
                                    } else if(block.day === yesterdaysDayOfWeek) {
                                        const minutesBlock: MinutesBlock = new MinutesBlock();
                                        minutesBlock.startMinutes = hhmmToMinutes(block.start);
                                        minutesBlock.endMinutes = minutesBlock.startMinutes + block.duration;
                                        if(minutesBlock.endMinutes > 1440) {
                                            minutesBlock.startMinutes = 0;
                                            minutesBlock.endMinutes = minutesBlock.endMinutes - 1440;
                                            this.todayMinutesBlocks[index].push(minutesBlock);
                                        }
                                    }
                                });
                            });
                        }
                        //default values if missing
                        if(!this.restaurant.hasOwnProperty('enableRoomCharge')) {
                            this.restaurant.enableRoomCharge = true;
                        }
                        if(this.restaurant.hasOwnProperty('deliveryFee')) {
                            this.restaurant.deliveryFee = +this.restaurant.deliveryFee;
                        }
                        if(this.restaurant.hasOwnProperty('transactionFeeFlat')) {
                            this.restaurant.transactionFeeFlat = +this.restaurant.transactionFeeFlat;
                        }
                        if(this.restaurant.hasOwnProperty('transactionFeePercentage')) {
                            this.restaurant.transactionFeePercentage = +this.restaurant.transactionFeePercentage;
                        }
                    } else {
                        console.error('restaurant does not exist');
                    }
                    resolve(this.restaurant);
                });
            } else {
                reject();
            }
        });
    }

    async postOrder(order) {
        const apiUrl = `${environment.apiBaseLocation}/create-order`;
        try {
            const token = nanoid(10);
            const time = Math.floor(Date.now() / 1000);
            order = {
                ...order,
                time,
                token,
                verification: verification(order, token, time),
            };
            const response = await axios({
                method: 'post',
                url: apiUrl,
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': 'application/json',
                },
                data : JSON.stringify(order)
            });
            if(response.data) {
                return response.data;
            }
        } catch (e) {
            console.error(e);
        }
    }

    async protectedApi(type, endPoint, postData, access_token) {
        const apiUrl = `${environment.apiBaseLocation}/${endPoint}`;
        if(!access_token) {
            access_token = await get('access_token', false);
        }
        try {
            const config: any = {
                method: type,
                url: apiUrl,
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': 'application/json',
                    Authorization: access_token
                }
            };
            if(type === 'post') {
                if('__proto__' in postData) {
                    delete(postData.__proto__);
                }
                config.data = JSON.stringify(postData);
            }
            const response = await axios(config);
            if(response.data.status === 'error'
                && response.data.reason === 'UNAUTHORIZED') {
                await remove('access_token');
                this.navController.navigateRoot('/admin/login');
                return null;
            }
            if(response.data) {
                return response.data;
            }
        } catch (e) {
            console.error(e);
        }
        return null;
    }

    async postAdminInfo(access_token) {
        return this.protectedApi('post', 'admin-info',
            {}, access_token);
    }

    async postOrdersCsv() {
        return this.protectedApi('post','export-orders-csv',
            {}, null);
    }

    async postStripeOnboard(createAccountLink) {
        return this.protectedApi('post','stripe-onboard',
            {createAccountLink}, null);
    }

    async postExportCsv() {
        return this.protectedApi('post','export-csv',
            {}, null);
    }

    async postImportCsv(csv) {
        return this.protectedApi('post','import-csv',
            {csv}, null);
    }

    async postSaveTimePeriod(timePeriod) {
        return this.protectedApi('post','save-time-period',
            timePeriod, null);
    }

    async getTimePeriods() {
        return this.protectedApi('get','time-periods',
            null, null);
    }

    async getOrders(data) {
        return this.protectedApi('post','orders',
            data, null);
    }

    async refundOrder(orderId, refundAmount) {
        return this.protectedApi('post','refund',
            {orderId, refundAmount: (+refundAmount)}, null);
    }

    async postDeleteTimePeriod(timePeriodIdentifier) {
        return this.protectedApi('post','delete-time-period',
            {identifier: timePeriodIdentifier}, null);
    }

    async postPublishTimePeriods() {
        return this.protectedApi('post','publish-time-periods',
            {}, null);
    }

    async postEditMenuType(operation, postData) {
        // return this.protectedApi('post','edit-menu-type',
        //     postData, null);
        return this.protectedApi('post','alter',
            {
                operation,  //'menu_type', 'menu_image_type'
                mode: postData.mode,
                payload: postData,
            }, null);
    }

    async postReorder(postData) {
        return this.protectedApi('post','alter',
            {
                operation: 'reorder',
                mode: 'reorder',
                payload: postData,
            }, null);
    }

    async postEditMenuTypeImage(postData) {
        return this.protectedApi('post','alter',
            {
                operation: 'menu_image_type',
                mode: 'edit',
                payload: postData,
            }, null);
    }

    async getSettings() {
        return this.protectedApi('get','settings',
            null, null);
    }

    async postSaveSettings(settings) {
        return this.protectedApi('post','alter',
            {
                operation: 'settings',
                mode: 'edit',
                payload: {
                    ...settings,
                },
            }, null);
    }

    async fetchS3SignedUrl() {
        return this.protectedApi('post','s3-signed-url',
            {}, null);
    }

    async updateS3Url(selectedPeriodIndex, menuExpandCategoryIndex, menuExpandMenuItemIndex, filename) {
        return this.protectedApi('post','update-s3-url',
            {
                periodIndex: selectedPeriodIndex,
                categoryIndex: menuExpandCategoryIndex,
                menuIndex: menuExpandMenuItemIndex,
                filename
            }, null);
    }

    //today's time like 14:00, if not defined current mm:ss
    //returns empty array if order is allowed,
    //returns null if restaurant does not accept orders for the day,
    //else array of allowed hh:mm
    canOrderAtTime(periodIndex: number, hhmm: string, ): string[] {
        const allowedHHMM = [];
        if(!hhmm) {
            hhmm = new Date().toLocaleTimeString('en-US',{
                hour12: false,
                timeZone: this.restaurant.timezone,
            });
        }
        let minute = hhmmToMinutes(hhmm);
        minute = minute % 1440;
        const mergedMinutesBlocks = [];
        let mergedMinuteBlock;
        for(const minuteBlock of this.todayMinutesBlocks[periodIndex]) {
            if(minute >= minuteBlock.startMinutes && minute <=minuteBlock.endMinutes ) {
                return [];
            }
            if(minuteBlock.endMinutes === 1440 && minuteBlock.startMinutes !== 0) {
                if(!mergedMinuteBlock) {
                    mergedMinuteBlock = new MinutesBlock();
                }
                mergedMinuteBlock.startMinutes = minuteBlock.startMinutes;
            }
            else if(minuteBlock.startMinutes === 0 && minuteBlock.endMinutes !== 1440) {
                if(!mergedMinuteBlock) {
                    mergedMinuteBlock = new MinutesBlock();
                }
                mergedMinuteBlock.endMinutes = minuteBlock.endMinutes;
            } else {
                mergedMinutesBlocks.push(minuteBlock);
            }
        }
        if(mergedMinuteBlock) {
            mergedMinutesBlocks.push(mergedMinuteBlock);
        }
        for(const minuteBlock of mergedMinutesBlocks) {
            allowedHHMM.push(minutesToHHMMAA(minuteBlock.startMinutes) +
                ' - ' +
                minutesToHHMMAA(minuteBlock.endMinutes));
        }
        if(allowedHHMM.length === 0) {
            return null;
        }
        return allowedHHMM;
    }
}

class MinutesBlock {
    startMinutes: number;
    endMinutes: number;
}
