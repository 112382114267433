import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MenuItemModalComponent } from './menu-item-modal/menu-item-modal.component';
import { ItemOrdered, ItemOrderedOption, ItemOrderedSubOptions, MenuItem } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class MenuItemModalService {

  constructor(private modalController: ModalController) {}

  async menuItemModal(menuItem: MenuItem, hidePricing: string): Promise<ItemOrdered | null> {
    const itemToOrder = this.initItemToOrder(menuItem);
    const modal = await this.modalController.create({
      component: MenuItemModalComponent,
      componentProps: {
        itemToOrder,
        hidePricing
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    // console.log('data = ', data);
    return data?.itemToAdd;
  }

  initItemToOrder(menuItem: MenuItem): ItemOrdered {
    let itemToOrder: ItemOrdered;
    if (menuItem.options) {
      const orderOptions: ItemOrderedOption[] = [];
      menuItem.options.forEach((option) => {
        const orderSuboptions: ItemOrderedSubOptions[] = [];
        option.subOptions?.forEach((subOption) => {
          orderSuboptions.push({
            ...subOption,
            selected: false,
          });
        });
        orderOptions.push({
          ...option,
          selectionCriteriaMet: false,
          subOptions: orderSuboptions,
          subOptionsText: '',
        });
      });
      itemToOrder = {
        ...menuItem,
        options: orderOptions,
        totalPriceAdder: 0,
        count: 1,
      };
    } else {
      itemToOrder = {
        ...menuItem,
        totalPriceAdder: 0,
        count: 1,
      };
    }
    return itemToOrder;
  }
}
